<template>
  <v-row align="center" justify="center" no-gutters class="overflow-x-auto">
    <vue-headful
      title="Automotriz Petersen - Politica"
      description="Politica de privacidad de la pagina de Automotriz Petersen"
    />
    <v-col cols="12" :xs="12" :sm="12" :md="6" :lg="6" :xl="4">
      <v-card class="my-3">
        <v-card-title>Política de privacidad</v-card-title>
        <v-card-text>
          1. <strong>Información que recopilamos:</strong> Recopilamos
          información proporcionada por el cliente al registrarse en nuestra
          aplicación, incluyendo, pero no limitado a, nombre, número de teléfono
          y dirección de correo electrónico. <br />2.
          <strong>Uso de la Información:</strong> Utilizamos la información
          recopilada para enviar mensajes informativos relacionados con la
          calidad de servicio, promociones e información sobre servicios
          contratados. No vendemos, alquilamos ni compartimos esta información
          con terceros sin el consentimiento del cliente. <br />3.
          <strong>Seguridad:</strong> Tomamos medidas de seguridad para proteger
          la información del cliente. Aunque hacemos todo lo posible para
          proteger la información del cliente, no podemos garantizar la
          seguridad de la información transmitida a través de nuestra
          aplicación. <br />4. <strong>Consentimiento:</strong> Al registrarse
          en nuestra aplicación, el cliente consiente el uso de su información
          como se describe en esta política de privacidad. <br />5.
          <strong>Cambios a esta Política:</strong> Podemos actualizar esta
          política de privacidad ocasionalmente. Si hacemos cambios
          significativos, notificaremos a los clientes a través de la aplicación
          o por otros medios. <br />6. <strong>Contacto:</strong> Para preguntas
          o inquietudes relacionadas con esta política de privacidad, por favor
          contacta a
          <strong @click="mail" class="text--primary" style="cursor: pointer">
            ventas@automotrizpetersen.cl
          </strong>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {},
  data: () => ({}),
  computed: {},
  watch: {},
  methods: {
    mail() {
      window.location.href = `mailto:ventas@automotrizpetersen.cl`;
    },
  },
};
</script>
<style></style>
